import React from "react"
import { Layout } from "./src/components/layout"
// import { Layout } from "./node_modules/@reflexjs/gatsby-theme-core/src/layout"

import Video from './src/components/video'

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    <Video src='/background-video.mp4' />

    {element}
  </Layout>
)

export default wrapPageElement
