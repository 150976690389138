import { graphql, useStaticQuery } from 'gatsby';

export const usePagesData = () => {

  const { allPage } = useStaticQuery(
    graphql`
      query {
				allPage {
					pages : nodes {
						data
						slug
					}
				}
			}
    `
	);

	console.log( { "allPage" : allPage } ) 

	return allPage;
};

