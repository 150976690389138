import { Styled } from "theme-ui";
import Card from "../../../../src/components/card";
import { Flex, Grid, Box, Link } from "theme-ui";
import * as React from 'react';
export default {
  Styled,
  Card,
  Flex,
  Grid,
  Box,
  Link,
  React
};