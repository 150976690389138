// import { Flexbox } from "reflexjs"
import React from 'react';
// import { MDXRenderer, Link } from "@reflexjs/gatsby-theme-core"
import { MDXRenderer } from "@reflexjs/gatsby-theme-core"
import { Image } from "@reflexjs/gatsby-plugin-image"

export const Post = ({
  title,
  excerpt,
  body,
  image,
  caption,
  date,
  datetime,
  author,
  timeToRead,
  tags,
}) => {

	console.log( "src/@reflexjs/gatsby-theme-post/post    Post    title:", title )

	return (
  <article py="8|12|14" variant="container">

		{image && (
      <div variant="container.lg">
        <Image
          src={image}
          title={title}
          alt={caption || title}
          caption={caption}
					mx="auto"
          // my="4|8|10"
          // margin-top="10px"
					overflow="hidden"
					
          sx={{
            img: {
              borderRadius: "md",
            },
						// border: 'green solid 4px',
						width: [ '75px', '100px', '125px', '150px', '175px', '200px' ],
						height: [ '75px', '100px', '125px', '150px', '175px', '200px' ],
          }}
        />
      </div>
    )}

    <div variant="container" maxW="null|null|null|900px">
      <div textAlign="center">
        {title && (
          <h1 variant="heading.h1" my="0" fontWeight="extrabold">
            {title}
          </h1>
        )}
        {excerpt && (
          <p fontSize="2xl" 
					maxW="700px" 
					mx="auto"
          sx={{
						fontSize: [ '14px', '16px', '18px', '20px', '22px', '24px' ],
						fontStyle: 'italic',
						fontWeight: '700',
         }}
					>
            {excerpt}
          </p>
        )}

      </div>
    </div>

    <div variant="container" maxW="null|null|null|900px">
      <MDXRenderer>{body}</MDXRenderer>
    </div>
  </article>

	) 

}

