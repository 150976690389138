import React from "react"
import filter from "lodash.filter"
import { Styled } from "theme-ui"
import PropTypes from "prop-types"
// import { Image } from "@reflexjs/gatsby-plugin-image"
import Image from "./image"
import { usePagesData } from "../hooks/usePagesData"
// import Grid from 'reflexjs'
import { Link } from "@reflexjs/gatsby-theme-core"

// import { Grid } from "reflexjs"
// import { Link } from "@reflexjs/gatsby-theme-core"

const Card = React.forwardRef(
		(
			{
				// type = `default`,
				children,
				textPosition = `left`,
				style,
				type = `default`,
				src,
				imageStyle,
				...props
			},
			ref
		) => { 

			src = src.replace( /^\./, "" )
			console.log( "src/components/card   src: ", src )
			console.log( "src/components/card   props: ", props )

			const data = usePagesData();
			console.log( { "data" : data } )

			const entries = filter( data.pages, page => page.slug === src )
			console.log( { "entries" : entries } )
			const page = entries[ 0 ].data
			console.log( "page.image", page.image )
			const slug = "pages" + entries[ 0 ].slug

			const image = page.image ? 
						<Image 
							src={ page.image } 
							alt={ page.title } 
							style={ imageStyle }
							sx={{
								variant: `image`,
								borderRadius: `10`,
								height: `inherit`,
								width: `inherit`,
							}}	/>
				: "<div></div>"

			const text = 	<div
				sx={{
					// border: `green solid 4px`,
					width: [ '150px', '200px', '300px', '400px', '300px', '400px' ],
					height: [ '120px', '200px', '300px', '400px', '300px', '400px' ],
					marginTop: "auto",
					marginBottom: "auto",
					color: 'black',
					// margin: '10px',
					padding: [ '10px', '10px', '10px', '30px', '30px', '30px', '30px' ],
					textAlign: 'center',
				}}
			>
				{ page.title && (
					<Styled.h2
						sx={{
							// mt: 0,
							ml: "auto",
							mr: "auto",
							textAlign: 'center',
							width: [ '100px', '100px', '270px', '250px', '250px', '300px' ],
							padding: [ '10px', '0px', null, null, null, null ],
						}}
					>
						{ page.title }
					</Styled.h2>
				)}
				
				{/* { page.caption && 
					<Styled.p 
						sx={{
							mt: 0,
							// ml: "auto",
							// mr: "auto",
							// textAlign: "center"
						}}
					> 
						{ page.caption }
					</Styled.p>
				} */}

				{ page.excerpt && 
					<Styled.p 
						sx={{
							'@media screen and ( max-width: 510px)' : {
								display: 'none'
								},
							mt: 0,
						}}
					> 
						{ page.excerpt }
					</Styled.p>
				}

			</div>


			if ( textPosition === "left" ) {

				return (
						<div
							ref={ref}
							sx={{
								variant: `card.${type}`,
								borderRadius: `10`,
								borderShadow: `5px 10px #444`,
								background: `white`,
								opacity: `0.8`,
								display: "flex",
								mt: 0,
								ml: "auto",
								mr: "auto",
								objectPosition: `center center`,
								textAlign: "center",
								// border: `yellow solid 4px`,
								width: [ '270px', '400px', '600px', '800px', '600px', '800px'],
								height: [ '120px', '200px', '300px', '400px', '300px', '400px' ],
								fontSize: [ '10px', '12px', '18px', '24px', '18px', '24px' ],
								...style,
							}}
						>

							<Link href={slug} display="inline-flex">
								{ text }
							<Link href={slug} display="inline-flex">
							</Link>
								{ image }
							</Link>

						</div>
				)

			}
			else {

				return (
					<div
					ref={ref}
					sx={{
						variant: `card.${type}`,
						borderRadius: `10`,
						borderShadow: `5px 10px #444`,
						background: `white`,
						opacity: `0.9`,
						display: "flex",
						mt: 0,
						ml: "auto",
						mr: "auto",
						objectPosition: `center center`,
						textAlign: "center",
						// border: `yellow solid 4px`,
						width: [ '270px', '400px', '600px', '800px', '600px', '800px'],
						height: [ '120px', '200px', '300px', '400px', '300px', '400px' ],
						fontSize: [ '10px', '12px', '18px', '24px', '18px', '24px' ],

				...style,
					}}
				>

					<Link href={slug} display="inline-flex">
						{ image }
	
						{ text }
					</Link>

				</div>
			)
		
		}
	} 
)

Card.propTypes = {
	src: PropTypes.string
}

export default Card

