import React from 'react';
import { Styled } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { find } from "lodash"
import Img from "gatsby-image"

const Image = ({ src, alt, title, style }) => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        images: nodes {
          relativePath
          src: childImageSharp {
            fluid(cropFocus: CENTER, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  `)

	console.log( "src/components/image    data.allFile.images", data.allFile.images )

  const image = find(data.allFile.images, {
    relativePath: src,
  })

  return (
    <figure
      sx={{
				mx: `auto`,
				// border: `purple solid 6px`,
				// outline: `red solid 2px`,
        overflow: `hidden`,
				// borderRadius: `50px`,
				width: [ '120px', '200px', '300px', '400px', '300px', '400px' ],
				height: [ '120px', '200px', '300px', '400px', '300px', '400px' ],
				m: `0`,
				objectPosition: `center center`,
				mt: `auto`,
				mb: `auto`,
				background: `transparent`,
        ...style,
      }}
    >
      {image ? (
        <Img fluid={image.src.fluid} alt={alt} title={title} />
      ) : (
        <Styled src={src} alt={alt} loading="lazy" />
      )}
    </figure>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
}

export default Image
