import { merge } from "reflexjs"
import base from "@reflexjs/preset-base"
import "typeface-open-sans"
import "typeface-prata"

const headingStyles = {
  fontFamily: `heading`,
  lineHeight: `heading`,
  fontWeight: `heading`,
  color: `text`,
  mt: 6,
  mb: 2,
  a: {
    color: `text`,
    textDecoration: `none`,
  },
}

export default merge(base, {

	colors: {
    black: "#191924",
    text: "#191924",
    background: "#fff",
    heading: "#07070a",
    primary: "#FFF",
    primaryHover: "#FFF",
    secondary: "#07070a",
    secondaryHover: "#191924",
    accent: "#2B59C3",
    accentHover: "#253C78",
    muted: "#f6f6f6",
    mutedHover: "#ebebeb",
    border: "#ebece9",
    borderHover: "#b9bdb3",
    modes: {
      dark: {
        black: "#191924",
        text: "#fff",
        background: "#191924",
        heading: "#fff",
        primary: "#FFF",
        primaryHover: "#F4D301",
        secondary: "#07070a",
        secondaryHover: "#191924",
        accent: "#2B59C3",
        accentHover: "#253C78",
        muted: "#2a2a3c",
        mutedHover: "#212130",
        border: "#2a2a3c",
        borderHover: "#434360",
      },
    },
  },

  fonts: {
    body: "Open Sans, sans-serif",
    heading: "inherit",
    monospace:
      "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
  },

  styles: {
    root: {
      fontSize: ["sm", "md", "lg", "xl"],

      ".gatsby-image-wrapper": {
        width: "auto",
        height: "auto",
				// marginTop: "20%",
				// marginBottom: "20%",
      },

		},
    a: {
      "&:hover, &:focus, &:active, &[aria-current=page]": {
        color: "text",
        textDecoration: "underline",
      },
    },

    strong: {
			color: "#161819",
			fontStyle: "italic",
		},

    h3: {
      ...headingStyles,
      fontSize: [3, 5, 6],
      color: `black`
    },

  },

  heading: {
    fontFamily: "body",
    letterSpacing: "normal",

    h1: {
      fontFamily: "heading",
      fontWeight: 400,
    },

    h2: {
      fontFamily: "heading",
      fontWeight: 400,
    },
  },

  button: {
    borderRadius: "4xl",
    fontSize: ["sm", "md"],

    sm: {
      px: 3,
      py: 2,
      fontSize: ["xs", "sm"],
    },

    lg: {
      px: 7,
      py: 4,
      fontSize: ["md", "lg"],
    },

    primary: {
      color: "black",

      "&:hover, &:focus": {
        color: "black",
      },
    },

    link: {
      "&:hover, &:focus": {
        bg: "transparent",
      },
    },
  },
  text: {
    small: {
      fontSize: [1],
    },
    branding: {
      textDecoration: `none`,
      fontWeight: `bold`,
      color: `text`,
      fontSize: [4, 5],
    },
    quote: {
      fontSize: [4, 5],
      lineHeight: [1.4],
      fontStyle: `italic`,
    },
    lead: {
      fontSize: [4, 5],
      lineHeight: [1.4, 1.6],
      color: `gray`,
      mb: 0,
      strong: {
        color: `accent`,
      },
    },

		fontSize:  [ 10, 14,16 ],
		
  },
  image: {
    avatar: {
      // borderRadius: `100%`,
			border: `pink solid 3px`,
			py : `auto`,
      width: `50px`,
      height: `50px`,
    },
    card: {
      // borderRadius: `100%`,
      width: `50px`,
      height: `50px`,
			border: `blue solid 2px`,
    },
  },
  card: {

		image: {
			// // borderRadius: `100%`,
			// maxWidth: `250px`,
			// maxHeight: `50px`,
			// width: [ 400, 400, 400, 400 ]
		},

		// default: {
    //   bg: `background`,
    //   boxShadow: `md`,
    //   borderRadius: 30,
		// 	opacity: `0.8`,
    //   // maxWidth: [`none`, `350px`],
    //   minWidth: [ null, `450px`],
    //   img: {
    //     width: `100%`,
    //     height: `auto`,
    //   },
    //   "> div": {
    //     p: [4, 6],
    //   },
    // },
    // icon: {
    //   textAlign: `center`,
    //   maxWidth: [`none`, `350px`],
    //   figure: {
    //     width: [`25%`, `40%`],
    //     height: `auto`,
    //     m: `0 auto`,
    //   },
    //   "> div": {
    //     py: [4, 6],
    //   },
    // },
  },

	container: {
    px: [4, 6, 6, 4],
    maxWidth: 1110,
    mx: "auto",
		// background: "white",
		width: "80%",
		// border: "red solid 4px",
		background: "white",
		opacity: "0.9",
		borderRadius: "10px",
		marginTop: "10px",

    // sm: {
    //   maxWidth: 740,
    // },

    // md: {
    //   maxWidth: 908,
    // },

    // lg: {
    //   maxWidth: 1224,
    // },

    // xl: {
    //   maxWidth: 1480,
    // },
  },

	// breakpoints: ['1200px', '1200px', '600px'],
	// breakpoints: ['40em', '62em', '90em'],
	breakpoints: ['510px', '630px', '950px', '1200px', '1400px' ],

	fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 22,
  },

})
