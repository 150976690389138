module.exports = [{
      plugin: require('../node_modules/@reflexjs/gatsby-plugin-metatags/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-reflexjs/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[],"remarkPlugins":[],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Adjuvant Technologies","short_name":"Adjuvant","start_url":"/","background_color":"#191924","theme_color":"#fee440","display":"standalone","icon":"content/images/favicon.png","opacity":"0.4","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"abc5df7c8fe579253728c55421e1df05"},
    },{
      plugin: require('../node_modules/@reflexjs/gatsby-plugin-metatags/gatsby-browser.js'),
      options: {"plugins":[],"global":{"title":"Adjuvant Technologies","description":"From data to intelligence","image":"default.jpg"},"types":["SitePage","Page","Post",{"type":"PostTag","defaults":{}},{"type":"Card","defaults":{}}],"paths":[{"pathname":"/blog{,/**}","defaults":{"title":"The Adjuvant Blog"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":true,"showFooter":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
